export const routes = {
    landing: '/',
    auth: '/auth',
    dashboard: '/dashboard',
    profile: '/profile',
    refinement: '/refinement',
    refinementSession: '/refinement/:sessionId/:title',
    privacyPolicy: '/privacy',
    news: '/news',
    support: '/support'
}