import { appState } from "../Store/appState";
import { URL } from "../Configs/Urls";
import { statusCode } from "@/Utils/StatusCodes";

export const getRefinementWorkItems = async (sessionId: any) => {
    let result: any;
    appState.loading = true;
    await fetch(URL.workItems.get, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refinementId: sessionId })
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = await res.json();
            }
        })
        .catch(error => {
            console.log(error);
        })

    appState.loading = false;
    return result;
}

export const getWorkItemsByIds = async (ids: any) => {
    let result: any;
    await fetch(URL.workItems.getById, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ workItemsIds: [ids] })
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = await res.json();
            }
        })
        .catch(error => {
            console.log(error);
        })

    return result;
}

export const updateWorkItem = async (workItemUpdateDTO: any) => {
    let result: any;
    await fetch(URL.workItems.update, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ update: workItemUpdateDTO })
    })
        .then(async (res) => {
            if (res.status === statusCode.OK) {
                result = await res.json();
            }
        })
        .catch((error) => {
            console.log(error);
        })

    return result;
}