import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6388d7be"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "work-item-content" }
const _hoisted_3 = { class: "bottom-content" }
const _hoisted_4 = { class: "item-titte-container" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.item?.id,
    class: _normalizeClass(['work-item', { 'disabled': _ctx.disabled }])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "left-item-content",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectWorkItem && _ctx.selectWorkItem(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.itemIcon,
              width: "15",
              height: "15",
              loading: "lazy"
            }, null, 8, _hoisted_5),
            _createElementVNode("span", null, _toDisplayString(_ctx.item?.id), 1)
          ]),
          _createElementVNode("div", null, "Effort: " + _toDisplayString(_ctx.item?.effort), 1)
        ]),
        _createElementVNode("div", null, _toDisplayString(_ctx.item?.title), 1)
      ])
    ])
  ], 10, _hoisted_1))
}