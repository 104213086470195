<template>
    <div class="modal-container">
        <div class="button-container">
            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#newBoardModal">
                <div class="sessionButtonContent">
                    <img :src="plusSign" width="30" height="30" loading="lazy" />
                    <span>New Session</span>
                </div>
            </button>
        </div>
        <div class="modal fade" id="newBoardModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="newBoardModalLabel">New Session</h5>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="titleForm" class="form-label">Title</label>
                            <input v-model.trim="data.title" type="text" class="form-control" id="titleForm"
                                placeholder="Session name..." />
                        </div>
                        <div class="flex-container">
                            <div class="left-dropdown">
                                <select v-model="data.type"
                                    @change="(event: any) => { handleTypeSelection(event.target.value) }"
                                    class="form-select" aria-label=".form-select-md">
                                    <option :value="undefined">Refinement Type...</option>
                                    <option value="Query">Query</option>
                                </select>
                            </div>
                            <div class="right-dropdown">
                                <div v-if="data.type === 'Query' && queriesList.length > 0 && !loadingQueries">
                                    <TreeNode :treeData="queriesList" @itemSelected="setQueryData"></TreeNode>
                                </div>
                                <div v-else-if="!loadingQueries && queriesList.length <= 0 && data.type === 'Query'"
                                    class="no-queries-container">No queries found</div>
                                <div v-if="loadingQueries" class="spinner-container">
                                    <div class="spinner d-flex justify-content-center align-items-center">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 flex-container-row">
                            <label class="form-check-label" for="restricted">Restrict session control to
                                owner</label>
                            <input v-model="data.restricted" class="form-check-input" type="checkbox" id="restricted">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-bs-dismiss="modal"
                            v-on:click="modalReset()">Cancel</button>
                        <button v-if="data.title.length > 0 && data.type" type="button" class="btn"
                            data-bs-dismiss="modal" v-on:click="saveModal()">Save</button>
                        <button v-else type="button" class="btn btn-disabled">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import TreeNode from '../Refinement/TreeNode.vue';
import { getRefinmentQueries, saveRefinementSession } from '@/Data/Services/DevopsService';
import { ConfirmDialog } from '../Dialogs/ConfirmDialog';
import { toast } from '@/Components/Dialogs/ToastMessage';
import { routes } from '@/Router/routes';
import { statusCode } from '@/Utils/StatusCodes';
import { messages } from '@/Utils/Messages';
import { icons } from '@/Utils/Assets';

export default defineComponent({
    components: {
        TreeNode
    },
    data() {
        return {
            plusSign: icons.general.add,
            data: {
                title: '',
                type: undefined,
                queryId: '',
                queryName: '',
                project: '',
                organization: '',
                restricted: false
            },
            queriesList: [] as any,
            loadingQueries: false
        }
    },
    methods: {
        handleTypeSelection(value: string) {
            if (value === 'Query') {
                this.getQueries();
            } else {
                this.data.type = undefined;
            }
        },
        setQueryData(id: string, name: string) {
            this.data.queryId = id;
            this.data.queryName = name;
        },
        async getQueries() {
            this.loadingQueries = true;

            const queriesList = await getRefinmentQueries();
            if (queriesList) {
                this.queriesList = queriesList;
            } else {
                toast(messages.error.fetchQuery, 'error');
            }

            this.loadingQueries = false;
        },
        async saveModal() {
            const result = await saveRefinementSession(this.data);
            if (result === statusCode.OK) {
                this.$emit("updateRefinementSessions");
                toast(messages.success.createSession, 'success');
            } else {
                ConfirmDialog(messages.alert.createSession,
                    'Cancel', undefined, () => {
                        this.$router.push(routes.profile);
                    })
            }
            this.modalReset();
        },
        modalReset() {
            this.data = {
                title: '',
                type: undefined,
                queryId: '',
                queryName: '',
                project: '',
                organization: '',
                restricted: false
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.flex-container {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;
}

.left-dropdown {
    width: 50%;
}

.right-dropdown {
    width: 50%;
}

.modal-content {
    -webkit-box-shadow: 0px 0px 15px 0px $primary-purple;
    -moz-box-shadow: 0px 0px 15px 0px $primary-purple;
    box-shadow: 0px 0px 15px 0px $primary-purple;
}

.dark .modal-content {
    background: $primary-dark;
}

.light .modal-content {
    background: $primary-light;
}

.sessionButtonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.2em;
}

.flex-container-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 1em
}

.btn {
    background: $primary-purple;
    color: $white;
    font-weight: bold;
    border: none;
}

.btn:hover {
    background: $secondary-purple;
    color: $white;
}

.btn-disabled {
    background: $third-dark;
    pointer-events: none;
}

.modal-header,
.modal-footer {
    border: none;
}

.form-check-input:checked {
    background-color: $primary-purple;
    border-color: $primary-purple;
}
</style>