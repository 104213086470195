export const ConfirmDialog = (message: string, negButton?: string, posButton?: string,
    confirm?: () => void, reject?: () => void) => {
    const dialogBox = document.querySelector('.dialog-container');
    const messageText = document.querySelector('.inner-container span');
    const cancelButton = document.querySelector('.cancel-button');
    const okButton = document.querySelector('.ok-button');

    const removeListeners = () => {
        if (cancelButton) {
            cancelButton.removeEventListener('click', onCancelButtonClick);
        }
        if (okButton) {
            okButton.removeEventListener('click', onOkButtonClick);
        }
    };

    const onCancelButtonClick = () => {
        if (reject) {
            reject();
        }
        dialogBox?.classList.remove('active');
        removeListeners();
    };

    // Function to handle ok button click
    const onOkButtonClick = () => {
        if (confirm) {
            confirm();
        }
        dialogBox?.classList.remove('active');
        removeListeners();
    };

    if (dialogBox && messageText && cancelButton && okButton) {
        if (negButton) {
            cancelButton.innerHTML = negButton;
            cancelButton.classList.add('btn-active');
        }
        okButton.innerHTML = posButton || 'OK';
        messageText.innerHTML = message;
        cancelButton?.addEventListener('click', onCancelButtonClick);
        okButton?.addEventListener('click', onOkButtonClick);
        dialogBox.classList.add('active');
    }
};
