<template>
  <div class="landing-container">
    <div class="welcome-container">
      <h1>Welcome to DevOps Refinement Portal</h1>
      <p>A solo project crafted to assist organisations in optimising their DevOps workflows.
        This platform offers a suite of tools to refine and plan your work efficiently, enabling you to estimate, edit,
        and manage sprint items seamlessly within Azure DevOps.
        Dive into a toolset tailored to enhance your sprint planning experience and track refinement
        statistics effortlessly. Empower your organization with streamlined DevOps processes,
        all from one centralised portal.</p>
    </div>
    <FeaturesCards />
  </div>
  <FooterBar />
</template>

<script lang="tsx">
import { user } from '../Data/Store/user';
import { defineComponent } from 'vue'
import FeaturesCards from "../Components/Cards/FeaturesCards.vue";
import { routes } from '@/Router/routes';
import FooterBar from '../Components/Navigation/FooterBar.vue';

export default defineComponent({
  beforeMount() {
    if (user.userData?.name && user.userData?.name !== '') {
      this.$router.push(routes.dashboard)
    }
  },
  components: {
    FeaturesCards,
    FooterBar
  }
})
</script>

<style lang="scss" scoped>
.landing-container {
  padding: 4em 1em 6em 1em;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  padding: 5em 0 5em 3em;
}

.welcome-container p {
  max-width: 50em;
}

p {
  color: $info-text;
}
</style>
