<template>
    <div class="userBar">
        <div class="user-item" v-for="user in users" :key="user">
            <div v-tooltip.left="user.name">
                <img class="avatar-img" :src="`data:image/png;base64,${user.avatar}`" width="30" height="30"
                    loading="lazy" />
            </div>
        </div>
    </div>
</template>

<script lang="tsx">
import { refinement } from '@/Data/Store/refinement';
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    setup() {
        const users = ref<Record<string, any>>(refinement.session.users || {});

        watch(() => refinement.session.users, (newVal) => {
            users.value = newVal || {};
        }, { immediate: true });

        return { users };
    }
})
</script>

<style lang="scss" scoped>
.userBar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-left: 1px solid $primary-purple;
    margin-left: 2em;
}

.user-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.3em 0 0 0.3em;
}

.avatar-img {
    border-radius: 50%;
}

@media screen and (max-width: 990px) {
    .userBar {
        max-width: 100%;
        margin-left: 0;
    }
}
</style>
