import { reactive } from 'vue';

interface UserState {
    userData: any[];
    theme: string | null;
    avatar: string | null;
}

// Create a new store instance.
const initialState: UserState = {
    userData: [],
    theme: localStorage.getItem('drp-theme') ? localStorage.getItem('drp-theme') : 'light',
    avatar: localStorage.getItem('drp-avatar') ? localStorage.getItem('drp-avatar') : '',
};

export const user: any = reactive({ ...initialState })

export const resetUserStore = () => {
    Object.assign(user, initialState);
}