<template>
    <div v-if="content" class="release-details">
        <div v-if="content.description">
            <p>{{ content.description }}</p>
        </div>
        <div v-if="content.features">
            <h3>What's New:</h3>
            <ul v-for="(feature, index) in content.features" :key="index">
                <li>{{ feature }}</li>
            </ul>
        </div>

        <div v-if="content.changes">
            <h3>Changes:</h3>
            <ul v-for="(change, index) in content.changes" :key="index">
                <li>{{ change }}</li>
            </ul>
        </div>

        <div v-if="content.issues">
            <h3>Known Issues:</h3>
            <ul v-for="(issue, index) in content.issues" :key="index">
                <li>{{ issue }}</li>
            </ul>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['content']
})

</script>

<style lang="scss" scoped>
.release-details h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
}

.release-details ul {
    margin-left: 20px;
}

.release-details li {
    margin-bottom: 10px;
}
</style>