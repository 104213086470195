<template>
    <div class="main-container" v-if="refinement.loaded && !appState.loading">
        <div class="top-container">
            <h3 class="refinement-title">{{ refinementTitle }}</h3>
            <div class="refinement-actions">
                <div class="refinement-actions-buttons">
                    <GenericButton text="Refresh" :iconSrc="icons.general.reloadWhite" @click="refreshSession"
                        :disabled="!interactable" />
                    <GenericButton text="Leave" :iconSrc="icons.general.leaveWhite" @click="leaveSession" />
                </div>
                <UserBar />
            </div>
        </div>
        <WorkItemSelectionVue v-if="isWorkItemSelection" />
        <div v-else-if="!appState.loading" class="session-container">
            <div class="left-container">
                <WorkItem v-for="(item, index) in workItems" :key="index" :item="item"
                    @editWorkItem="editWorkItem(index)" @itemSelect="itemSelect(index)" :theme="theme"
                    :disabled="!interactable" />
            </div>
            <div v-if="showEditWorkItemModal" class="right-container">
                <EditWorkItemView
                    v-bind:data="{ title: workItems[selectedWorkItem].title + ': Work items edit in progress!' }" />
            </div>
            <div v-else-if="selectedWorkItem >= 0" class="right-container">
                <LargeTextContainers :selectedWorkItem="workItems[selectedWorkItem]" :refinementId="sessionId"
                    @updateSelectedWorkItem="updateSelectedWorkItem" />
                <div class="estimate-tiles">
                    <h3>Votes</h3>
                    <div class="tile-container">
                        <button type="button" class="tile" v-for="tileVal in votingTile" :key="tileVal"
                            v-on:click="addToVotingList(tileVal, $event)">
                            {{ tileVal }}
                        </button>
                    </div>
                    <div v-if="votesList.length > 0" class="current-votes-container">
                        <b>{{ `Current Votes (${votesList.length} / ${Object.keys(refinement.session.users).length})`
                            }}</b>
                        <div class="voting-row">
                            <div class="vote-container" v-for="vote in votesList" :key="vote">
                                <div class="vote">
                                    <div>{{ revealed ? vote.value : '...' }}</div>
                                </div>
                                <div class="user-vote-info-container">
                                    <img style="border-radius: 50%;"
                                        :src="`data:image/png;base64,${vote.userInfo?.avatar}`" width="25" height="25"
                                        loading="lazy" />
                                    <span style="text-align:center;">{{ formatUserName(vote.userInfo?.user) }}</span>
                                </div>
                            </div>
                        </div>
                        <GenericButton v-if="!revealed" btnClass="reveal-button" text="Reveal" @click="revealVotes"
                            :disabled="!interactable" />
                        <SubmitEstimate v-else v-bind:uniqueVotes="uniqueVotes" @revote="itemSelect(selectedWorkItem)"
                            v-on:submitEstimate="(value) => submitEstimate(value)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import io from 'socket.io-client';
import LargeTextContainers from '../Components/Refinement/LargeTextContainer.vue';
import EditWorkItemView from '../Components/WorkItems/EditWorkItemView.vue';
import SubmitEstimate from '../Components/Refinement/SubmitEstimate.vue';
import WorkItemSelectionVue from '../Components/Refinement/WorkItemSelection.vue';
import UserBar from '../Components/Refinement/UserBar.vue';
import WorkItem from '../Components/Refinement/WorkItem.vue';
import { useRoute } from 'vue-router';
import { defaultVotingTile } from '../Utils/VotingTiles';
import { user } from '../Data/Store/user';
import { appState } from '@/Data/Store/appState';
import { setOneActiveAndOthersInactive, setEnabledDisabled, formatName, setActiveEnactiveMultipleItems, setSelectedItemActiveByIndex } from '../Utils/HelperFunctions';
import { getRefinementWorkItems, getWorkItemsByIds, updateWorkItem } from '../Data/Services/WorkItemsService';
import { socketConnection } from '../Data/Configs/Sockets';
import { toast } from '@/Components/Dialogs/ToastMessage';
import GenericButton from '@/Components/Buttons/GenericButton.vue';
import { routes } from '@/Router/routes';
import { baseUrl } from '@/Data/Configs/Urls';
import { messages } from '@/Utils/Messages';
import { icons } from '@/Utils/Assets';
import { refinement, resetRefinementStore } from '@/Data/Store/refinement';

interface RouteParams {
    sessionId?: string;
    title?: string;
}

export default defineComponent({
    props: ['theme'],
    setup() {
        const route = useRoute();
        const { sessionId, title }: RouteParams = route.params;
        const refinementTitle = title?.replaceAll("-", " ");
        return { refinementTitle, sessionId };
    },
    beforeUnmount() {
        if (this.socket) {
            this.socket.disconnect();
        }
        resetRefinementStore();
    },
    async mounted() {
        await this.getWorkItems()

        if (!this.workItems || this.workItems.length <= 0) {
            toast(messages.error.fetchWorkItems, 'error');
            this.$router.replace(routes.refinement);
        } else {
            this.socket = io(baseUrl, socketConnection);

            this.socket.on('connect_error', () => {
                this.$router.replace(routes.refinement);
            });

            this.socket.on('connect', () => {
                const userData = { userId: user.userData?.id, userName: user.userData?.name, avatar: user.avatar };
                this.socket.emit('joinSession', { userData: userData, refinementId: this.sessionId });
            });

            this.socket.on('userJoined', (data: any) => {
                refinement.session.users = data;
            });

            this.socket.on('sessionData', (data: any) => {
                refinement.session.data = data;
                this.interactable = (refinement.session.data.restricted && refinement.session.users[user.userData?.id].owner) || !refinement.session.data.restricted;
                this.initSessionData(data);
                refinement.loaded = true;
            });

            this.socket.on('userDisconnect', (data: any) => {
                refinement.session.users = data || [];
            });

            this.socket.on('itemSelected', (index: number) => {
                this.workItemChange(index);
                this.resetRefinementData();
            })

            this.socket.on('workItemUpdated', async (workItemsIds: any[]) => {
                const items = await getWorkItemsByIds(workItemsIds);
                if (items && items.length > 0) {
                    items.forEach((updatedItem: any) => {
                        const index = this.workItems.findIndex((item: any) => item.id === updatedItem.id);
                        if (index !== -1) {
                            this.workItems[index] = updatedItem;
                        }
                    });
                }
            })

            this.socket.on('userVoted', (vote: any) => {
                this.votesList.push(vote);
            })

            this.socket.on('votesRevealed', (data: any) => {
                this.votesList = data?.userVotes;
                this.uniqueVotes = data?.uniqueVotes;
                this.revealed = true;
                setEnabledDisabled('tile', false);
            })

            this.socket.on('refinementReset', () => {
                this.resetRefinementData();
            })

            this.socket.on('sessionRefreshed', async () => {
                this.selectedWorkItem = -1;
                this.resetRefinementData();
                await this.getWorkItems();
                this.socket.emit('getSessionData', (data: any) => {
                    this.initSessionData(data);
                });
            })
        }
    },
    components: {
        UserBar,
        WorkItem,
        LargeTextContainers,
        EditWorkItemView,
        SubmitEstimate,
        WorkItemSelectionVue,
        GenericButton
    },
    data() {
        return {
            appState,
            icons,
            refinement,
            votingTile: defaultVotingTile,
            votesList: [] as any,
            showEditWorkItemModal: false,
            selectedWorkItem: -1,
            revealed: false,
            uniqueVotes: [] as any,
            isWorkItemSelection: false,
            socket: undefined as any,
            workItems: [] as any,
            interactable: false
        }
    },
    methods: {
        async initSessionData(data: any) {
            this.votesList = await data?.votesList;
            this.uniqueVotes = await data?.uniqueVotes;
            this.revealed = await data?.revealed;
            this.selectedWorkItem = await data?.itemSelected;
            setSelectedItemActiveByIndex(await data?.itemSelected, 'work-item');
            setEnabledDisabled('tile', !await data?.revealed);
        },
        async getWorkItems() {
            const items = await getRefinementWorkItems(this.sessionId);
            if (items) {
                this.workItems = items;
            }
        },
        editWorkItem(index: any) {
            if (index === this.selectedWorkItem) {
                this.showEditWorkItemModal = !this.showEditWorkItemModal;
            } else {
                this.showEditWorkItemModal = true;
                this.selectedWorkItem = index;
                this.resetRefinementData();
                this.socket.emit('resetRefinement');
            }
        },
        itemSelect(index: any) {
            if (this.interactable) {
                this.socket.emit('selectedItem', index);
                this.workItemChange(index);
                this.resetRefinementData();
            }
        },
        workItemChange(index: any) {
            setSelectedItemActiveByIndex(index, 'work-item');
            this.showEditWorkItemModal = false;
            this.selectedWorkItem = index;
        },
        addToVotingList(voteValue: string, e: Event) {
            const addVote = (value: string) => {
                if (this.selectedWorkItem >= 0) {
                    //if hasn't been revealed and user hasn't voted. 
                    if (!this.revealed && !this.votesList.some((vote: any) => vote.userInfo?.user === user.userData?.name)) {
                        setOneActiveAndOthersInactive(e.target, 'tile', true);
                        this.socket.emit('voteAdded', { value: value, userInfo: { user: user.userData?.name, avatar: user.avatar } });
                    } else if (!this.revealed) {
                        //if hasn't been revealed and user has voted. Replace old vote with the new one
                        setOneActiveAndOthersInactive(e.target, 'tile', true);
                        this.votesList.forEach((vote: any) => {
                            if (vote.userInfo?.user === user.userData?.name) {
                                vote.value = value;
                            }
                        });
                        this.socket.emit('voteUpdated', { value: value, userInfo: { user: user.userData?.name, avatar: user.avatar } });
                    }
                }
            }

            addVote(voteValue);
        },
        revealVotes() {
            if (this.interactable && this.votesList.length > 0) {
                this.socket.emit('revealVotes');
            }
        },
        resetRefinementData() {
            this.votesList = [];
            this.uniqueVotes = [];
            this.revealed = false;
            setOneActiveAndOthersInactive(null, 'tile', false);
            setEnabledDisabled('tile', true);
        },
        async submitEstimate(value: string) {
            if (this.interactable) {
                value = value === '?' ? '' : value;
                const workItemUpdateDTO = {
                    refinementId: this.sessionId,
                    workItemId: this.workItems[this.selectedWorkItem].id,
                    operation: [{
                        'op': 'replace',
                        'path': '/fields/Microsoft.VSTS.Scheduling.Effort',
                        'value': value
                    }]
                }
                const updatedWorkItem = await updateWorkItem(workItemUpdateDTO);
                if (updatedWorkItem) {
                    const workItemId = this.workItems[this.selectedWorkItem].id;
                    this.workItems[this.selectedWorkItem] = updatedWorkItem
                    this.socket.emit('workItemUpdate', workItemId);
                    this.resetRefinementData();
                    this.socket.emit('resetRefinement');

                    let itemFound = false;
                    for (let index = 0; index < this.workItems.length; index++) {
                        const item = this.workItems[index];
                        if (!item.effort || item.effort === "") {
                            this.selectedWorkItem = index;
                            itemFound = true;
                            break;
                        }
                    }

                    if (!itemFound) {
                        this.selectedWorkItem = -1;
                        setActiveEnactiveMultipleItems('active-item', false);
                    }

                    this.itemSelect(this.selectedWorkItem);
                    this.$nextTick(function () {
                        toast(messages.success.submitEstimate, 'success')
                    })
                } else {
                    toast(messages.error.submitEstimate, 'error');
                }
            }
        },
        formatUserName(userName: string) {
            return formatName(userName);
        },
        async refreshSession() {
            if (this.interactable) {
                this.socket.emit('refreshSession');
            }
        },
        leaveSession() {
            this.$router.push(routes.refinement);
        },
        updateSelectedWorkItem(updatedItem: any) {
            if (this.interactable) {
                this.workItems[this.selectedWorkItem] = updatedItem;
                this.socket.emit('workItemUpdate', this.workItems[this.selectedWorkItem].id);
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.main-container {
    display: flex;
    flex-direction: column;
    margin: 5em 1em 1em 1em;
}

.top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 100%;
}

.refinement-title {
    max-width: 50%;
    word-wrap: break-word;
}

.session-container {
    display: flex;
    flex-direction: row;
}

.left-container {
    display: flex;
    flex-direction: column;
    min-width: 24em;
    max-width: 24em;
}

.right-container {
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    width: 100%;
}

.left-container,
.right-container {
    max-height: 86vh;
    overflow-y: auto;
}

.light .tile {
    color: $black;
    background: $third-dark;
}

.dark .tile {
    color: $white;
    background: $secondary-dark;
}

.tile-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4em;
}

.tile {
    width: 2.5em;
    height: 2.5em;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    font-size: larger;
}

.tile:hover {
    background: $primary-purple;
    color: $white;
}

h3 {
    font-weight: bold;
}

.current-votes-container {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}

.voting-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.vote-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

.light .vote {
    transition: background 0.5s ease-in-out;
    background: $third-dark;
    color: $black;
}

.dark .vote {
    transition: background 0.5s ease-in-out;
    background: $secondary-dark;
    color: $white;
}

.vote {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    text-align: center;
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 5px;
    font-weight: bold;
    color: $white;
}

.reveal-button {
    margin-top: 1.5em;
    width: 5em;
}

.active-item {
    background: $primary-purple !important;
    color: $white !important;
}

.disabled-item {
    pointer-events: none;
}

.user-vote-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2em;
}

.estimate-tiles {
    margin-bottom: 5em;
}

.refinement-actions {
    display: flex;
    flex-direction: row;
    max-width: 50%;
}

.refinement-actions-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    padding-left: 2em;
}

@media screen and (max-width: 990px) {
    .top-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .refinement-actions {
        max-width: 100%;
        gap: 0.5em;
    }

    .session-container {
        display: flex;
        flex-direction: column;
    }

    .left-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 100%;
    }

    .right-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin-top: 1em;
        overflow-x: hidden;
    }

    .vote-container {
        margin-bottom: 0;
    }

    .left-container {
        max-height: 37vh;
        overflow-y: auto;
    }

    .refinement-actions-buttons {
        flex-direction: column;
    }
}
</style>
