import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7ac93196"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "userBar" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "user-item",
        key: user
      }, [
        _withDirectives((_openBlock(), _createElementBlock("div", null, [
          _createElementVNode("img", {
            class: "avatar-img",
            src: `data:image/png;base64,${user.avatar}`,
            width: "30",
            height: "30",
            loading: "lazy"
          }, null, 8, _hoisted_2)
        ])), [
          [
            _directive_tooltip,
            user.name,
            void 0,
            { left: true }
          ]
        ])
      ]))
    }), 128))
  ]))
}