<template>
	<div class="app-container" :class="theme">
		<NavigationBar :theme="theme" @themeToggle="themeToggle" />
		<div class="main-content">
			<LoadingSpinner v-if="appState.loading" />
			<router-view :theme="theme" />
			<ConfirmDialogTemplate />
			<ToastMessage />
		</div>
	</div>
</template>

<script lang="tsx">
import NavigationBar from './Components/Navigation/NavigationBar.vue';
import { defineComponent } from 'vue';
import { user } from './Data/Store/user';
import { appState } from './Data/Store/appState';
import LoadingSpinner from './Components/Spinners/LoadingSpinner.vue';
import ConfirmDialogTemplate from './Components/Dialogs/ConfirmDialogTemplate.vue';
import ToastMessage from './Components/Dialogs/ToastMessage.vue';

export default defineComponent({
	components: {
		NavigationBar,
		LoadingSpinner,
		ConfirmDialogTemplate,
		ToastMessage,
	},
	methods: {
		themeToggle() {
			this.theme = this.theme === 'light' ? 'dark' : 'light';
			localStorage.setItem('drp-theme', this.theme);
		},
	},
	data() {
		return {
			appState,
			theme: user.theme
		}
	},
})
</script>

<style lang="scss">
::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-thumb {
	background: #BDBDBD;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: #6E6E6E;
}

.light,
html,
body {
	background: $primary-light;
	color: black;
	transition: background 0.5s ease-in-out;
	margin: 0;
	padding: 0;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;

	/* Hides scrollbar background */
	::-webkit-scrollbar-track {
		background: $primary-light;
	}
}

.dark,
html,
body {
	background: $primary-dark;
	color: white;
	transition: background 0.5s ease-in-out;
	margin: 0;
	padding: 0;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;

	/* Hides scrollbar background */
	::-webkit-scrollbar-track {
		background: $primary-dark;
	}
}

.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main-content {
	flex-grow: 1;
	overflow-y: auto;
}
</style>