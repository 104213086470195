<template>
    <div class="settings-container">
        <h4 style="margin-bottom: 1em;">Profile Settings</h4>
        <div class="fields-list-container">
            <div class="field-container">
                <label for="organizationMenu" class="form-label">Organisation</label>
                <div class="select-row">
                    <select id="organizationMenu" v-model="selectedOrganization"
                        @change="(event: any) => { selectOrganization(event.target.value) }" class="select-drop"
                        aria-label="Organization">
                        <option :value="''">Choose organisation...</option>
                        <option v-for="organization in organizationsList" :key="organization"
                            v-bind:value="organization">
                            {{ organization }}
                        </option>
                    </select>
                    <div v-if="loadingOrg" class="spinner d-flex justify-content-center align-items-center">
                        <div class="spinner-border" role="status"></div>
                    </div>
                    <div v-else v-on:click="getOrganizations()">
                        <img class="icon-refresh" width="30" height="30"
                            :src="theme === 'dark' ? icons.general.reloadWhite : icons.general.reloadBlack"
                            loading="lazy" />
                    </div>
                </div>
            </div>
            <div class="field-container" v-if="projectsEnabled">
                <label for="projectsMenu" class="form-label">Project</label>
                <div class="select-row">
                    <select id="projectsMenu" v-model="selectedProject"
                        @change="(event: any) => { selectProject(event.target.value) }" class="select-drop"
                        aria-label="Project">
                        <option :value="''">Choose project...</option>
                        <option v-for="project in projectsList" :key="project" v-bind:value="project">
                            {{ project }}
                        </option>
                    </select>
                    <div v-if="loadingProj" class="spinner d-flex justify-content-center align-items-center">
                        <div class="spinner-border" role="status"></div>
                    </div>
                    <div v-else v-on:click="getProjects()">
                        <img class="icon-refresh" width="30" height="30"
                            :src="theme === 'dark' ? icons.general.reloadWhite : icons.general.reloadBlack"
                            loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { user } from '../Data/Store/user';
import {
    getOrganizations,
    getProjects,
    getProfileSettings,
    saveOrganization,
    saveProject
} from '../Data/Services/DevopsService';
import { toast } from '@/Components/Dialogs/ToastMessage';
import { statusCode } from '@/Utils/StatusCodes';
import { messages } from '@/Utils/Messages';
import { icons } from '@/Utils/Assets';

export default defineComponent({
    props: ['theme'],
    async mounted() {
        await this.getProfileSettings();
    },
    data() {
        return {
            user,
            organizationsList: [] as any,
            projectsList: [] as any,
            loadingOrg: false,
            loadingProj: false,
            selectedProject: '',
            selectedOrganization: '',
            projectsEnabled: false,
            icons
        };
    },
    methods: {
        async selectOrganization(org: string) {
            if (org) {
                this.loadingOrg = true;
                const result = await saveOrganization(org);
                if (result === statusCode.OK) {
                    toast(messages.success.saveOrganization, 'success');
                    this.selectedProject = '';
                    await this.getProjects();
                } else {
                    toast(messages.error.saveOrganization, 'error');
                }
                this.loadingOrg = false;
            }
        },
        async selectProject(proj: string) {
            if (proj) {
                this.loadingProj = true;
                const result = await saveProject(proj);
                if (result === statusCode.OK) {
                    toast(messages.success.saveProject, 'success');
                } else {
                    toast(messages.error.saveProject, 'error');
                }
                this.loadingProj = false;
            }
        },
        async getProfileSettings() {
            this.loadingOrg = true;
            this.loadingProj = true;
            const data = await getProfileSettings();
            if (data === undefined) {
                toast(messages.error.profileSettings, 'error');
            }

            this.selectedOrganization = data?.organization || '';

            if (this.selectedOrganization || data?.project) {
                this.selectedProject = data?.project || '';
                this.projectsEnabled = true;
            }
            this.organizationsList = data?.organizationsList;
            this.projectsList = data?.projectsList;
            this.loadingOrg = false;
            this.loadingProj = false;
        },
        async getOrganizations() {
            this.loadingOrg = true;
            const data = await getOrganizations();
            if (data === undefined) {
                toast(messages.error.getOrganizations, 'error');
            }
            this.organizationsList = data;
            this.loadingOrg = false;
        },
        async getProjects() {
            this.loadingProj = true;
            const data = await getProjects();
            if (data === undefined) {
                toast(messages.error.getProjects, 'error');
            }
            this.projectsEnabled = true;
            this.projectsList = data;
            this.loadingProj = false;
        }
    }
})

</script>

<style lang="scss" scoped>
.settings-container {
    padding: 5em 1em 1em 2em;
}

.fields-list-container {
    width: 25%;
}

.field-container {
    margin-bottom: 1.5em;
}

.select-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.form-label {
    font-weight: bold;
}

.select-drop {
    width: 100%;
    min-width: 15em;
    padding: 7px;
    border-radius: 3px;
    outline: none;
}

.icon-refresh {
    cursor: pointer;
}

@media screen and (max-width: 400px) {
    .fields-list-container {
        width: 100%;
    }
}
</style>