import { appState } from "../Store/appState";
import { URL } from "../Configs/Urls";
import { statusCode } from "@/Utils/StatusCodes";

export const getProfileSettings = async () => {
    let result: any;
    await fetch(URL.profile.get, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = await res.json();
            }
        })
        .catch(error => {
            console.log(error);
        })
    return result;
}

export const getProfileAvatar = async () => {
    let avatar: string = '';
    await fetch(URL.profile.avatar, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                avatar = await res.json();
            }
        })
        .catch(error => {
            console.log(error);
        })
    return avatar;
}

export const getTeams = async () => {
    await fetch(URL.teams.get, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async (resp: any) => {
            if (resp.status === statusCode.OK) {
                const data = await resp.json();
                console.log(data);
            }
        })
}

export const getOrganizations = async () => {
    let result: any;
    await fetch(URL.organizations.get, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = await res.json();
            }
        })
        .catch(error => {
            console.log(error);
        })
    return result;
}

export const saveOrganization = async (org: string) => {
    let result: any;

    await fetch(URL.organizations.save, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organization: org })
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = res.status;
            }
        })
        .catch(error => {
            console.log(error);
        })
    return result;
}

export const getProjects = async () => {
    let result: any;
    await fetch(URL.projects.get, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = await res.json();
            }
        })
        .catch(error => {
            console.log(error);
        })
    return result;
}

export const saveProject = async (proj: string) => {
    let result: any;

    await fetch(URL.projects.save, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project: proj })
    })
        .then(async res => {
            if (res.status === statusCode.OK) {
                result = res.status;
            }
        })
        .catch(error => {
            console.log(error);
        })
    return result;
}

export const getRefinementSessions = async () => {
    let result: any;
    appState.loading = true;
    await fetch(URL.sessions.get, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async (res) => {
            if (res.status === statusCode.OK) {
                const data = await res.json();
                result = data.refinementSessions
            }
        })
        .catch(err => {
            console.log(err);
        })
    appState.loading = false;
    return result;
}

export const deleteSession = async (sessionId: string) => {
    let result: any;

    await fetch(URL.sessions.delete, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId: sessionId })
    })
        .then(async (res) => {
            result = res.status;
        })
        .catch(err => {
            console.log(err);
            result = statusCode.BadRequest;
        })

    return result;
}

export const getRefinmentQueries = async () => {
    let result: any;

    await fetch(URL.sessions.getQueries, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(async (resp: any) => {
            if (resp.status === statusCode.OK) {
                const data = await resp.json();
                if (data.value.length > 0) {
                    result = data.value;
                }
            }
        })
        .catch((error) => {
            console.log(error);
        })
    return result;
}

export const saveRefinementSession = async (data: any) => {
    let result: any;

    await fetch(URL.sessions.save, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then(async (resp: any) => {
            result = resp.status;
        })
        .catch((error) => {
            console.log(error);
        })

    return result;
}