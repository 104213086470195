<template>
    <div class="vote-submission-container">
        <span><b>Votes selected:&nbsp;</b>choose one to submit</span>
        <div class="votes">
            <div v-for="vote in uniqueVotes" :key="vote">
                <button type="button" class="vote" v-on:click="submitEstimate(vote)" :disabled="!interactable">{{ vote
                    }}</button>
            </div>
        </div>
        <div class="custom-value">
            <b>Custom Value:</b>
            <input class="input-box" v-model="customValue" placeholder="" type="number" :disabled="!interactable" />
        </div>
        <div class="estimates-footer">
            <GenericButton btnClass="submit-button" text="Submit" @click="submitEstimate(customValue)"
                :disabled="!interactable" />
            <GenericButton btnClass="submit-button" text="Re-vote" @click="revote" :disabled="!interactable" />
        </div>
    </div>
</template>
<script lang="tsx">
import { defineComponent } from 'vue';
import { toast } from '@/Components/Dialogs/ToastMessage';
import { messages } from '@/Utils/Messages';
import { refinement } from '@/Data/Store/refinement';
import { user } from '@/Data/Store/user';
import GenericButton from '../Buttons/GenericButton.vue';

export default defineComponent({
    props: ['uniqueVotes'],
    components: {
        GenericButton
    },
    data() {
        return {
            customValue: '',
            interactable: (refinement.session.data.restricted && refinement.session.users[user.userData?.id].owner) || !refinement.session.data.restricted
        }
    },
    methods: {
        submitEstimate(value: string) {
            if (value) {
                this.$emit('submitEstimate', value);
            } else {
                toast(messages.error.submitCustomEstimate, 'error');
            }
        },
        revote() {
            this.$emit('revote');
        }
    }
})
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -webkit-appearance: textfield;
    /* Chrome, Safari, Edge */
    -moz-appearance: textfield;
    /* Firefox */
    appearance: textfield;
    /* Standard */
}

.vote-submission-container {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}

.light .vote {
    background: $third-dark;
    color: $black;
}

.dark .vote {
    background: $secondary-dark;
    color: $white;
}

.vote:not(:disabled):hover {
    background: $primary-purple;
    color: $white;
}

.votes {
    display: flex;
    flex-direction: row;
    gap: 0.4em;
    margin-top: 0.5em;
    flex-wrap: wrap;
}

.vote {
    color: $white;
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 5px;
    font-weight: bold;
    border: none;
}

// .vote:not(:disabled):hover {
//     background: $primary-purple;
// }

.light .vote:hover,
.dark .vote:hover {
    transition: none;
}

.custom-value {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    gap: 1em;
}

.light .input-box {
    transition: background 0.5s ease-in-out;
    background: $third-dark;
    color: $black
}

.dark .input-box {
    transition: background 0.5s ease-in-out;
    background: $secondary-dark;
    color: $white;
}

.input-box {
    max-width: 3em;
    border-radius: 3px;
    border: none;
    outline: none;
    background: $secondary-dark;
    color: $white;
    border: solid 1px $primary-purple;

    &:disabled {
        border: none;
    }
}

.submit-button {
    margin-top: 0.5em;
}

.estimates-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
</style>