import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-13f2c82e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-content" }
const _hoisted_3 = { class: "links-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(" All rights reserved. Content & design by DevOps Refinement Portal, 2024. "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirect($event, _ctx.routes.privacyPolicy)))
        }, "Privacy Policy"),
        _createTextVNode(" · "),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirect($event, _ctx.routes.support)))
        }, "Support")
      ])
    ])
  ]))
}