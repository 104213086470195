<template>
    <div>{{ data?.title }}</div>
    <img :src="img" width="200" height="200" style="margin-top: 20px;" loading="lazy" />
</template>

<script lang="tsx">
import { images } from '@/Utils/Assets';
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        data: {} as any
    },
    setup() {
        const img = images.pepe;

        return { img };
    },
    data() {
        return {
            images
        }
    }
})
</script>

<style lang="scss" scoped></style>
