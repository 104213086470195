<template>
  <div class="refinement-container">
    <RefinementModal @updateRefinementSessions="updateRefinementSessions()" />
    <div class="active-sessions-container">
      <div class="cards-container" v-for="session in refinementSessions" :key="session.id">
        <RefinementCard :refinementSession="session" @startRefinement="startRefinementSession(session)"
          @deleteSession="deleteSession(session)" />
      </div>
    </div>
  </div>
</template>

<script lang="tsx">
import { useRouter } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue'
import { deleteSession, getRefinementSessions } from '../Data/Services/DevopsService';
import { ConfirmDialog } from '../Components/Dialogs/ConfirmDialog';
import RefinementModal from '../Components/Modals/RefinementModal.vue';
import { toast } from '@/Components/Dialogs/ToastMessage';
import RefinementCard from '@/Components/Cards/RefinementCard.vue';
import { routes } from '@/Router/routes';
import { statusCode } from '@/Utils/StatusCodes';
import { messages } from '@/Utils/Messages';

interface Session {
  id: string,
  canDeleteRestricted: boolean,
  title: string,
  type: string,
  queryName: string,
  restricted: boolean
}

export default defineComponent({
  components: {
    RefinementModal,
    RefinementCard,
  },
  setup() {
    const router = useRouter();
    const refinementSessions = ref([]) as any;

    const getSessions = async () => {
      const sessions = await getRefinementSessions();
      if (!sessions) {
        ConfirmDialog(messages.alert.getSessions,
          'Cancel', undefined, () => {
            router.push(routes.profile);
          })
      } else {
        refinementSessions.value = sessions;
      }
    }

    onMounted(async () => {
      await getSessions();
    });

    return { refinementSessions, getSessions }
  },
  methods: {
    startRefinementSession(session: Session) {
      const sessionTitle = session?.title?.replaceAll(" ", "-");
      const sessionId = session?.id;
      this.$router.push(`refinement/${sessionId}/${sessionTitle}`);
    },

    async updateRefinementSessions() {
      this.getSessions();
    },

    async deleteSession(session: Session) {
      if (session?.restricted && !session?.canDeleteRestricted) {
        toast(messages.error.deleteSessionNotOwner, 'error');
      } else {
        ConfirmDialog(messages.alert.deleteSession, 'Cancel', undefined, async () => {
          const deleteResult = await deleteSession(session?.id);
          if (deleteResult === statusCode.OK) {
            const index = this.refinementSessions.findIndex((item: Session) => item.id === session?.id);
            if (index !== -1) {
              this.refinementSessions.splice(index, 1);
              toast(messages.success.deleteSession, 'success');
            }
          } else {
            toast(messages.error.deleteSession, 'error');
          }
        });
      }
    }
  },
})
</script>

<style lang="scss" scoped>
.refinement-container {
  display: flex;
  flex-direction: column;
  padding: 5em 1em 1em 1em;
  width: 100%;
}

.active-sessions-container {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  margin-top: 2em;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.cards-container {
  display: flex;
  flex-direction: column;
  width: 22em;
  max-width: 22em;
  min-width: 15em;
  border-radius: 8px;
  box-sizing: border-box;
  max-height: 15em;
  overflow: hidden;
}

.dark .cards-container {
  transition: background 0.5s ease-in-out;
  background-color: $secondary-dark;
  color: $white;
  box-shadow: 0 2px 8px $primary-purple;
}

.light .cards-container {
  transition: background 0.5s ease-in-out;
  background-color: $third-light;
  color: $black;
  box-shadow: 0 2px 8px $light-box-shadow;
}
</style>