<template>
    <div class="toast-container">
        <div class="title">
            <img class="toast-icon" width="25" height="25" />
            <div class="toast-message"></div>
        </div>
    </div>
</template>
<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({})
</script>

<style lang="scss" scoped>
.toast-container {
    position: absolute;
    right: 3%;
    top: 10%;
    width: 20em;
    transform: translateY(-20em);
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    visibility: hidden;
}

.dark .toast-container {
    -webkit-box-shadow: 0px 0px 15px 0px $primary-purple;
    -moz-box-shadow: 0px 0px 15px 0px $primary-purple;
    box-shadow: 0px 0px 15px 0px $primary-purple;
    background: $secondary-dark;
    color: $white;
}

.light .toast-container {
    background: $third-light;
    color: $black;
    box-shadow: 0 2px 8px $light-box-shadow;
}

.toast-message {
    border-radius: 10px;
    color: $white;
    word-wrap: break-word;
}

.title {
    padding: 1.5em 1em 1.5em 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: medium;
    align-content: center;
    gap: 10px;
}

.dark .toast-message {
    color: $white;
}

.light .toast-message {
    color: $black;
}

.active {
    visibility: visible;
    transform: translateY(0%);
}

@media screen and (max-width: 990px) {
    .toast-container {
        position: fixed;
        right: 3%;
        margin-top: 4em;
        top: -100%;
        width: 20em;
        transition: top 0.5s ease-in-out;
        transform: translateY(-100%);
    }

    .active {
        top: 3%;
        transform: translateY(0%);
    }
}
</style>
