export function Tooltip(el: HTMLElement, binding: any) {
    const tooltipText = binding.value?.text || binding.value;
    const position = binding.value.position || getPositionClass(binding.modifiers);
    el.classList.add("with-tooltip");
    el.classList.add(`tooltip--${position}`);

    const toggleTooltip = () => {
        el.classList.toggle('tooltip-visible');
        setTimeout(hideTooltip, 3000);
    };

    const hideTooltip = () => {
        el.classList.remove('tooltip-visible');
    };

    el.addEventListener('click', toggleTooltip);

    // Remove tooltip on click outside
    document.addEventListener('click', (event) => {
        if (!el.contains(event.target as Node)) {
            hideTooltip();
        }
    });

    // Set tooltip text
    el.setAttribute("data-tooltip", tooltipText);
}

function getPositionClass(modifiers: any) {
    if (modifiers.top) {
        return 'top';
    } else if (modifiers.bottom) {
        return 'bottom';
    } else if (modifiers.left) {
        return 'left';
    } else if (modifiers.right) {
        return 'right';
    }

    return 'top';
}
