import { reactive } from 'vue';

interface AppState {
    loading: boolean
}

// Create a new store instance.
const initialState: AppState = {
    loading: false
};

export const appState: any = reactive({ ...initialState })

export const resetAppStore = () => {
    Object.assign(appState, initialState);
}