<template>
    <button v-if="iconSrc" :class="[iconClass, 'toolbar-button']" @click="handleClick" :disabled="disabled">
        <img v-if="iconSrc" :src="iconSrc" width="24" height="24" loading="lazy" />
        <div class="text" v-else-if="text">{{ text }}</div>
    </button>
    <button v-else-if="text" :class="[iconClass, 'toolbar-button']" @click="handleClick" :disabled="disabled">
        {{ text }}
    </button>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { refinement } from '@/Data/Store/refinement';
import { user } from '@/Data/Store/user';

export default defineComponent({
    props: {
        iconSrc: {
            type: String,
            required: false
        },
        iconClass: {
            type: String,
            default: ''
        },
        onClick: {
            type: Function,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClick() {
            if ((refinement.session.data.restricted && refinement.session.users[user.userData?.id].owner) || !refinement.session.data.restricted) {
                this.onClick();
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.toolbar-button {
    font-weight: bold;
    font-size: medium;
    padding: 0.2rem 0.4rem;
    color: $white;
    height: 34px;
}

.dark .toolbar-button {
    transition: background 0.5s ease-in-out;
    background-color: $primary-dark;
    border: 1px solid $secondary-dark;

    &:hover,
    &.is-active {
        background-color: $dark-hover-dark;
    }
}

.light .toolbar-button {
    transition: background 0.5s ease-in-out;
    background-color: $info-text;
    border: 1px solid $dark-hover-dark;

    &:hover,
    &.is-active {
        background-color: $dark-hover-dark;
    }
}

.toolbar-button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>